import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { defaultState } from '../defaultState'

import { SubmitBtn, PageTitle, SelectWidget } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { Formstyles } from './AddonStyles'


class BrandForm extends Component {
  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.disableForm === false &&
      window.location.pathname !== '/master-data/brands/new'
    ) {
      this.disableForm(true)
    }
  }

  componentDidMount() {
    if (window.location.pathname !== '/master-data/brands/new') {
      this.disableForm(true)
    }
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  render() {
    const { classes, data, onSubmit, isLoading } = this.props
    return (
      <Grid item xs={12}>
        {isLoading && <div className="lmask" id="lmask" />}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            company_id: yup.string().required('กรุณากรอก'),
            name: yup.string().required('กรุณากรอก')
          })}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values)
            if (values.id === '')
              resetForm(defaultState)
          }}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <Paper className={classes.paper}>
              <div className={classes.root}>
                <PageTitle
                  formTitle="ยี่ห้อ"
                  action={this.disableForm}
                  edit={this.state.disableForm}
                  id={values.id}
                  view="brand edit"
                />
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <SelectWidget
                          url="api/companies"
                          value={values.company_id || ''}
                          name="company_id"
                          title="บริษัท *"
                          onChange={setFieldValue}
                          disabled={this.state.disableForm}
                        />
                        {touched.company_id && errors.company_id && (
                          <span className="form-text--error">
                            {errors.company_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          name="name"
                          label="ชื่อ"
                          fullWidth
                          value={values.name || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.name && errors.name && (
                          <span className="form-text--error">
                            {errors.username}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="search_key"
                          label="ชื่อย่อ"
                          fullWidth
                          value={values.search_key || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="active"
                              value="1"
                              onChange={(e) => {
                                let val = (e.target.checked) ? '1' : '0'
                                setFieldValue("active", val)
                              }}
                              onBlur={handleBlur}
                              checked={
                                values.active.toString() === '1'
                              }
                            />
                          }
                          label="Active"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubmitBtn
                          disabled={this.state.disableForm}
                          onClear={() => resetForm(defaultState)}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </div>
            </Paper>
          )}
        />
      </Grid>
    )
  }
}
export default withStyles(Formstyles)(BrandForm)
