import React, { PureComponent } from 'react'
import axios from 'axios'
import { Formik, FieldArray } from 'formik'
import * as yup from 'yup'
import { getIdtoArray } from 'lib/helper'
import { config } from 'lib/authFunc'

import { defaultState } from '../defaultState'

import { CheckboxList, SubmitBtn, PageTitle } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { Formstyles } from './AddonStyles'


class UserForm extends PureComponent {
  _isMounted = false

  state = defaultState

  getUserGroupMobile = async () => {
    try {
      const mobile = axios.get(process.env.REACT_APP_API_URL + '/api/user-groups-mobile', config)
      const web = axios.get(process.env.REACT_APP_API_URL + '/api/user-groups', config)
      const branch = axios.get(process.env.REACT_APP_API_URL + '/api/branches', config)

      const [groupMobile, groupWeb, branches] = await Promise.all([
        mobile,
        web,
        branch
      ])

      if (this._isMounted) {
        this.setState({
          webGroup: groupWeb.data,
          mobileGroup: groupMobile.data,
          branches: branches.data,
          formLoading: false
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.disableForm === false &&
      window.location.pathname !== '/master-data/users/new'
    ) {
      this.disableForm(true)
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getUserGroupMobile()
    if (window.location.pathname !== '/master-data/users/new') {
      this.disableForm(true)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  genYupValidation = id => {
    let schema = {
      username: yup.string().required('กรุณากรอก'),
      name: yup.string().required('กรุณากรอก'),
      surname: yup.string().required('กรุณากรอก'),
      checkBranchList: yup
        .array()
        .nullable()
        .required('กรุณาเลือกอย่างน้อย 1 สาขา'),
      checkList: yup
        .boolean()
        .oneOf([true], 'กรุณาเลือกอย่างน้อย 1 สิทธิ์')
    }

    if (id === '') schema.password = yup.string().required('กรุณากรอก')

    return schema
  }

  render() {
    const { user, onSubmit, classes, isLoading } = this.props
    const validationSchema = this.genYupValidation(user.id)

    const placeholder = user.id === '' ? 'รหัสผ่าน' : 'รหัสผ่าน ( หากไม่ต้องการเปลี่ยนรหัสผ่านไม่ต้องกรอก ) '

    user.checkBranchList =
      typeof user.relations !== 'undefined' &&
      getIdtoArray(user.relations.branch)
    user.checkWebList =
      typeof user.relations !== 'undefined' &&
      getIdtoArray(user.relations.user_group)
    user.checkMobileList =
      typeof user.relations !== 'undefined' &&
      getIdtoArray(user.relations.mobile_group)

    if (parseInt(user.is_admin) === 1) {
      if (typeof this.state.branches.data === 'object')
        user.checkBranchList = getIdtoArray(this.state.branches.data)
      if (typeof this.state.webGroup.data === 'object')
        user.checkWebList = getIdtoArray(this.state.webGroup.data)
      if (typeof this.state.mobileGroup.data === 'object')
        user.checkMobileList = getIdtoArray(this.state.mobileGroup.data)
    }

    return (
      <Grid item xs={12}>
        {isLoading || this.state.formLoading ? <div className="lmask" id="lmask" /> : null}
        <Formik
          enableReinitialize
          initialValues={user}
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values)
            if (values.id === '') resetForm(defaultState)
          }}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => {
            values.checkList = values.checkMobileList.length > 0 || values.checkWebList.length > 0 ? true : false
            return <Paper className={classes.paper}>
              <div className={classes.root}>
                <PageTitle
                  formTitle="ผู้ใช้งาน"
                  action={this.disableForm}
                  edit={this.state.disableForm}
                  id={values.id}
                  view="user edit" />
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          name="username"
                          label="ชื่อผู้ใช้งาน"
                          fullWidth
                          value={values.username || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.username && errors.username && (
                          <span className="form-text--error">
                            {errors.username}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required={values.id === '' ? true : false}
                          name="password"
                          label={placeholder}
                          fullWidth
                          value={values.password || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.password && errors.password && (
                          <span className="form-text--error">
                            {errors.password}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="active"
                              value="1"
                              onChange={(e) => {
                                let val = (e.target.checked) ? '1' : '0'
                                setFieldValue("active", val)
                              }}
                              onBlur={handleBlur}
                              checked={
                                values.active.toString() === '1'
                              }
                            />
                          }
                          label="Active"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="employee_code"
                          label="รหัสพนักงาน"
                          fullWidth
                          value={values.employee_code || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          name="name"
                          label="ชื่อ"
                          fullWidth
                          value={values.name || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.name && errors.name && (
                          <span className="form-text--error">
                            {errors.name}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          name="surname"
                          label="นามสกุล"
                          fullWidth
                          value={values.surname || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.surname && errors.surname && (
                          <span className="form-text--error">
                            {errors.surname}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="phone"
                          label="เบอร์โทร"
                          fullWidth
                          value={values.phone || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          label="อีเมลล์"
                          fullWidth
                          value={values.email || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="is_admin"
                              value="1"
                              onChange={(e) => {
                                let val = (e.target.checked) ? '1' : '0'
                                setFieldValue("is_admin", val)

                                if (e.target.checked) {
                                  setFieldValue('checkBranchList', getIdtoArray(this.state.branches.data))
                                  setFieldValue('checkMobileList', getIdtoArray(this.state.mobileGroup.data))
                                  setFieldValue('checkWebList', getIdtoArray(this.state.webGroup.data))
                                } else {
                                  setFieldValue('checkBranchList', [])
                                  setFieldValue('checkMobileList', [])
                                  setFieldValue('checkWebList', [])
                                }

                              }}
                              onBlur={handleBlur}
                              checked={
                                values.is_admin.toString() === '1'
                              }
                            />
                          }
                          label="Admin"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {errors.checkBranchList && (
                          <span className="form-text--error">
                            {errors.checkBranchList}
                          </span>
                        )}
                        <FieldArray
                          name="checkBranchList"
                          render={arrayHelpers => (
                            <CheckboxList
                              title="สาขา"
                              list={this.state.branches}
                              onChange={(e, id) => {
                                if (e.target.checked) {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkBranchList', id)
                                  } else {
                                    arrayHelpers.push(id)
                                  }
                                } else {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkBranchList', [])
                                  } else {
                                    const idx = values.checkBranchList.indexOf(
                                      id
                                    )
                                    arrayHelpers.remove(idx)
                                  }
                                }
                              }}
                              checkList={values.checkBranchList}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {errors.checkList && (
                          <span className="form-text--error">
                            {errors.checkList}
                          </span>
                        )}
                        <FieldArray
                          name="checkMobileList"
                          render={arrayHelpers => (
                            <CheckboxList
                              title="สิทธิ์มือถือ"
                              list={this.state.mobileGroup}
                              onChange={(e, id) => {
                                if (e.target.checked) {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkMobileList', id)
                                    setFieldValue('is_login_web', 1)
                                  } else {
                                    arrayHelpers.push(id)
                                    setFieldValue('is_login_web', 1)
                                  }
                                } else {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkMobileList', [])
                                    setFieldValue('is_login_web', '')
                                  } else {
                                    const idx = values.checkMobileList.indexOf(
                                      id
                                    )
                                    arrayHelpers.remove(idx)
                                  }
                                }
                              }}
                              checkList={values.checkMobileList}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {errors.checkList && (
                          <span className="form-text--error">
                            {errors.checkList}
                          </span>
                        )}
                        <FieldArray
                          name="checkWebList"
                          render={arrayHelpers => (
                            <CheckboxList
                              title="สิทธิ์เว็บ"
                              list={this.state.webGroup}
                              onChange={(e, id) => {
                                if (e.target.checked) {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkWebList', id)
                                    setFieldValue('is_login_mobile', 1)

                                  } else {
                                    arrayHelpers.push(id)
                                    setFieldValue('is_login_mobile', 1)
                                  }
                                } else {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkWebList', [])
                                    setFieldValue('is_login_mobile', '')
                                  } else {
                                    const idx = values.checkWebList.indexOf(id)
                                    arrayHelpers.remove(idx)
                                  }
                                }
                              }}
                              checkList={values.checkWebList}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SubmitBtn
                          disabled={this.state.disableForm}
                          onClear={() => resetForm(defaultState)} />
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </div>
            </Paper>
          }}
        />
      </Grid>
    )
  }
}
export default withStyles(Formstyles)(UserForm)
