import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { formatDate } from 'lib/helper'
import { companyWithBranch } from 'lib/loadOption'
import { DocItems } from './DocItems'

import {
  SubmitBtn,
  PageTitle,
  SelectWidget,
  DatePickerWidget,
  ConfirmDlg
} from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography';

import { genOptionList, genRelateOptionList } from 'lib/helper'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { Formstyles, EditTableStyle } from '../../AddonStyles'
import { defaultState } from '../defaultState'

class DocForm extends Component {
  _isMounted = false
  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  setRelateOption = (name, value) => {
    let items = this.state.bkcompany
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(value))
    if (idx < 0) {
      this.setState({ branches: [] })
      return
    }

    this.setState({
      branches: genRelateOptionList(
        [items[idx]],
        'id',
        'name',
        'branch',
        'company_id'
      )
    })
  }


  componentDidMount() {
    this._isMounted = true
    if (window.location.pathname !== '/warehouse/goods-receives/new') {
      this.disableForm(true)
    }

    companyWithBranch().then(res => {
      if (this._isMounted) {
        this.setState({
          companies: genOptionList(res, 'id', 'name'),
          bkcompany: res
        })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    if (data.company_id !== '') {
      this.setState({ disableForm: true },
        () => this.setRelateOption('company_id', data.company_id))

    }

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  deleteItem = id => {
    this.props.onDeleteItem(id)
  }

  editItem = (id, data) => {
    this.props.onEditItem(id, data)
  }

  createItem = data => {
    this.props.onCreateItem(data)
  }

  toggleConfirmDlg = id => {
    this.setState({ isModalOpen: !this.state.isModalOpen, currentId: id })
  }

  deleteGoodsReceiveDetail = () => {
    const { currentId } = this.state
    this.props.ondeleteAllDocItem(currentId)
    this.setState({ isModalOpen: false })
  }

  render() {
    const { data, onSubmit, classes, isLoading } = this.props
    data['detailItem'] =
      typeof data.relations !== 'undefined' ? data.relations.detail : []

    return (
      <Fragment>
        {isLoading && <div className="lmask" id="lmask" />}
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={yup.object().shape({
              company_id: yup.string().required('กรุณากรอก'),
              branch_id: yup.string().required('กรุณากรอก'),
              document_type_id: yup.string().required('กรุณากรอก')
            })}
            onSubmit={values => onSubmit(values)}
            render={({
              values,
              errors,
              touched,
              resetForm,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Paper className={classes.paper}>
                <div className={classes.root}>
                  <PageTitle
                    formTitle="ใบรับสินค้า"
                    action={this.disableForm}
                    edit={this.state.disableForm}
                    id={values.id}
                    status={values.status}
                    view="goodsreceive edit"
                  />
                  <form onSubmit={handleSubmit}>
                    <fieldset disabled={this.state.disableForm}>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="order_no"
                            label="เลขที่เอกสาร"
                            fullWidth
                            value={values.order_no || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/master-data/doc-types?function_type=GR"
                            value={values.document_type_id || ''}
                            name="document_type_id"
                            title="ชนิดเอกสาร *"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.document_type_id &&
                            errors.document_type_id && (
                              <span className="form-text--error">
                                {errors.document_type_id}
                              </span>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.companies}
                            value={values.company_id || ''}
                            name="company_id"
                            title="บริษัท*"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.setRelateOption(name, value)
                            }}
                            disabled={this.state.disableForm}
                          />
                          {touched.company_id && errors.company_id && (
                            <span className="form-text--error">
                              {errors.company_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.branches}
                            value={values.branch_id || ''}
                            name="branch_id"
                            title="สาขา*"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.branch_id && errors.branch_id && (
                            <span className="form-text--error">
                              {errors.branch_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="ref_no"
                            label="เอกสารอ้างอิง"
                            fullWidth
                            value={values.ref_no || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePickerWidget
                            title="วันที่รับสินค้า"
                            name="delivery_date"
                            value={values.delivery_date || ''}
                            onValueChange={value => {
                              setFieldValue('delivery_date', formatDate(value))
                            }}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="description"
                            label="รายละเอียด"
                            fullWidth
                            value={values.description || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="customer_name"
                            label="หุ้นส่วนทางธุรกิจ"
                            fullWidth
                            value={values.customer_name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SubmitBtn
                            disabled={this.state.disableForm}
                            onClear={() => resetForm(defaultState)}
                          />
                        </Grid>
                      </Grid>
                    </fieldset>
                  </form>
                </div>
                <Divider className={classes.divider} />
                <MuiThemeProvider theme={EditTableStyle()}>
                  <Typography component="h2" gutterBottom style={{ color: 'red' }}>
                    กรุณากรอกข้อมูลในคอลัมน์ที่มี * ให้ครบทุกคอลัมน์
                  </Typography>
                  <DocItems
                    status={values.status}
                    rows={values.detailItem}
                    docId={values.id}
                    companyId={values.company_id}
                    onCreate={this.createItem}
                    onEdit={this.editItem}
                    onDelete={this.deleteItem}
                    disabled={!this.state.disableForm}
                  />
                </MuiThemeProvider>

                <ConfirmDlg
                  title="ยืนยันการเปลี่ยนแปลงบริษัท"
                  content="หากคุณเปลี่ยนบริษัท ข้อมูลรายการสินค้าด้านล่างจะถูกลบทั้งหมด ยืนยันการเปลี่ยนแปลง?"
                  onToggle={this.toggleConfirmDlg}
                  onConfirm={this.deleteGoodsReceiveDetail}
                  isOpen={this.state.isModalOpen}
                />
              </Paper>
            )}
          />
        </Grid>
      </Fragment>
    )
  }
}
export default withStyles(Formstyles)(DocForm)
