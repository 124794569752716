import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { genOptionList, genRelateOptionList } from 'lib/helper'
import { warehoseWithBin } from 'lib/loadOption'

import { defaultState } from '../defaultState'

import { SubmitBtn, SelectWidget, PageTitle } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { Formstyles } from './AddonStyles'

class BinDetailForm extends Component {
  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  setRelateOption = (name, value) => {
    let items = this.state.bkwarehose
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(value))
    if (idx < 0) return
    this.setState({
      bin: genRelateOptionList(
        [items[idx]],
        'id',
        'name',
        'bin',
        'warehouse_id'
      )
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // if(prevState.disableForm === false
    //     && window.location.pathname !== '/master-data/storagebin-details/new') {
    //   this.disableForm(true)
    // }
  }

  componentDidMount() {
    if (window.location.pathname !== '/master-data/storagebin-details/new') {
      this.disableForm(true)
    }

    warehoseWithBin().then(res => {
      this.setState({
        warehose: genOptionList(res, 'id', 'name'),
        bkwarehose: res,
        formLoading: false
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    if (data.warehouse_id !== '')
      this.setRelateOption('warehouse_id', data.warehouse_id)
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  render() {
    const { data, onSubmit, classes, isLoading } = this.props

    return (
      <Grid item xs={12}>
        {isLoading || this.state.formLoading ? <div className="lmask" id="lmask" /> : null}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            warehouse_id: yup.string().required('กรุณากรอก'),
            bin_id: yup.string().required('กรุณากรอก'),
            uom_id: yup.string().required('กรุณากรอก'),
            relative_priority: yup.string().required('กรุณากรอก')
          })}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values)
            if (values.id === '')
              resetForm(defaultState)
          }}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <Paper className={classes.paper}>
              <div className={classes.root}>
                <PageTitle
                  formTitle="รายละเอียด Storage Bin"
                  action={this.disableForm}
                  edit={this.state.disableForm}
                  id={values.id}
                  view="bin edit"
                />
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={4}>
                        <SelectWidget
                          withOption={true}
                          options={this.state.warehose}
                          value={values.warehouse_id || ''}
                          name="warehouse_id"
                          title="คลังสินค้า *"
                          onChange={(name, value) => {
                            setFieldValue(name, value)
                            this.setRelateOption(name, value)
                          }}
                          disabled={this.state.disableForm}
                        />
                        {touched.warehouse_id && errors.warehouse_id && (
                          <span className="form-text--error">
                            {errors.warehouse_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SelectWidget
                          withOption={true}
                          options={this.state.bin}
                          value={values.bin_id || ''}
                          name="bin_id"
                          title="Storage Bin * "
                          onChange={setFieldValue}
                          disabled={this.state.disableForm}
                        />
                        {touched.bin_id && errors.bin_id && (
                          <span className="form-text--error">
                            {errors.bin_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SelectWidget
                          url="api/uoms"
                          value={values.uom_id || ''}
                          name="uom_id"
                          title="หน่วยนับ * "
                          onChange={setFieldValue}
                          disabled={this.state.disableForm}
                        />
                        {touched.uom_id && errors.uom_id && (
                          <span className="form-text--error">
                            {errors.uom_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="relative_priority"
                          label="Relative Priority"
                          fullWidth
                          value={values.relative_priority || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.relative_priority &&
                          errors.relative_priority && (
                            <span className="form-text--error">
                              {errors.relative_priority}
                            </span>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="max_weigh"
                          label="น้ำหนักสูงสุด "
                          fullWidth
                          value={values.max_weigh || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="max_qty"
                          label="จำนวนสูงสุด "
                          fullWidth
                          value={values.max_qty || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="used"
                          label="ใช้แล้ว "
                          fullWidth
                          value={values.used || ''}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="available"
                          label="คงเหลือ "
                          fullWidth
                          value={values.available || ''}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="active"
                              value="1"
                              onChange={(e) => {
                                let val = (e.target.checked) ? '1' : '0'
                                setFieldValue("active", val)
                              }}
                              onBlur={handleBlur}
                              checked={
                                values.active.toString() === '1'
                              }
                            />
                          }
                          label="Active"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubmitBtn
                          disabled={this.state.disableForm}
                          onClear={() => resetForm(defaultState)}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </div>
            </Paper>
          )}
        />
      </Grid>
    )
  }
}
export default withStyles(Formstyles)(BinDetailForm)
