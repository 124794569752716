const COMPANY_ID = process.env.REACT_APP_COMPANY_ID
const MAIN_BRANCH = process.env.REACT_APP_MAIN_BRANCH
const COMPANY_NAME = `${process.env.REACT_APP_COMPANY_NAME}`
const PUSHER_KEY = `${process.env.REACT_APP_PUSHER_KEY}`
const API_URL = `${process.env.REACT_APP_API_URL}`
const FORM_URL = `${process.env.REACT_APP_FORM_URL}`
export {
    API_URL,
    COMPANY_ID,
    MAIN_BRANCH,
    COMPANY_NAME,
    PUSHER_KEY,
    FORM_URL
}
