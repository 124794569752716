import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { companyWithBranch } from 'lib/loadOption'
import { defaultState } from '../defaultState'

import { SelectWidget, SubmitBtn, PageTitle } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { Formstyles, EditTableStyle } from './AddonStyles'

import { StorageBin } from './StorageBin'

import { genOptionList, genRelateOptionList } from 'lib/helper'

class StoreForm extends Component {

  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  setRelateOption = (name, value) => {
    //if (value === '') return  
    let items = this.state.bkcompany
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(value))
    if (idx < 0) return

    this.setState({
      branches: genRelateOptionList(
        [items[idx]], 'id', 'name', 'branch', 'company_id'),
    })

  }

  loadBranch = (value) => {
    companyWithBranch().then(res => {
      this.setState({
        companies: genOptionList(res, 'id', 'name'),
        bkcompany: res,
        formLoading: false
      }, () => this.setRelateOption('company_id', value))
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.disableForm === false &&
      window.location.pathname !== '/master-data/stores/new'
    ) {
      this.disableForm(true)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    this.loadBranch(data.company_id)
    //this.setRelateOption('company_id', data.company_id)
  }

  componentDidMount() {
    if (window.location.pathname !== '/master-data/stores/new') {
      this.disableForm(true)
    }

  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  deleteItem = (id, data) => {
    this.props.onDeleteItem(id, data)
  }

  editItem = (id, data) => {
    this.props.onEditItem(id, data)
  }

  createItem = data => {
    this.props.onCreateItem(data)
  }

  render() {
    const { classes, data, onSubmit, isLoading } = this.props
    data.binItems =
      typeof data.relations !== 'undefined' ? data.relations.bin : []
    console.log('branch', this.state.branches)
    return (
      <Fragment>
        {isLoading || this.state.formLoading ? <div className="lmask" id="lmask" /> : null}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            code: yup.string().required('กรุณากรอก'),
            company_id: yup.string().required('กรุณากรอก'),
            branch_id: yup.string().required('กรุณากรอก'),
            name: yup.string().required('กรุณากรอก')
          })}
          onSubmit={values => onSubmit(values)}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <Paper className={classes.paper}>
              <div className={classes.root}>
                <PageTitle
                  formTitle="คลังสินค้า"
                  action={this.disableForm}
                  edit={this.state.disableForm}
                  id={values.id}
                  view="whbin edit"
                />
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={6}></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="code"
                          label="รหัส"
                          fullWidth
                          value={values.code || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          name="name"
                          label="ชื่อ"
                          fullWidth
                          value={values.name || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                        {touched.name && errors.name && (
                          <span className="form-text--error">
                            {errors.username}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="search_key"
                          label="ชื่อย่อ"
                          fullWidth
                          value={values.search_key || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectWidget
                          withOption={true}
                          options={this.state.companies}
                          value={values.company_id || ''}
                          name="company_id"
                          title="บริษัท*"
                          onChange={(name, value) => {
                            setFieldValue(name, value)
                            this.setRelateOption(name, value)
                          }}
                          disabled={this.state.disableForm}
                        />
                        {touched.company_id && errors.company_id && (
                          <span className="form-text--error">
                            {errors.company_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectWidget
                          withOption={true}
                          options={this.state.branches}
                          value={values.branch_id || ''}
                          name="branch_id"
                          title="สาขา*"
                          onChange={setFieldValue}
                          disabled={this.state.disableForm}
                        />
                        {touched.branch_id && errors.branch_id && (
                          <span className="form-text--error">
                            {errors.branch_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="description"
                          label="รายละเอียด"
                          fullWidth
                          value={values.description || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.disableForm}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="active"
                              value="1"
                              onChange={(e) => {
                                let val = (e.target.checked) ? '1' : '0'
                                setFieldValue("active", val)
                              }}
                              onBlur={handleBlur}
                              checked={
                                values.active === true || values.active === 1
                              }
                            />
                          }
                          label="Active"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SubmitBtn
                          disabled={this.state.disableForm}
                          onClear={() => resetForm({})}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </div>
              <Divider className={classes.divider} />

              <h5>Storage Bin</h5>

              <MuiThemeProvider theme={EditTableStyle()}>
                <StorageBin
                  rows={values.binItems}
                  code={values.code}
                  warehouseId={values.id}
                  onCreate={this.createItem}
                  onEdit={this.editItem}
                  onDelete={this.deleteItem}
                  disabled={!this.state.disableForm}
                />
              </MuiThemeProvider>
            </Paper>
          )}
        />
      </Fragment>
    )
  }
}
export default withStyles(Formstyles)(StoreForm)
